<template>
  <div class="edit-message">
    <CForm
      ref="createMemberMessage"
      v-on:submit.prevent="updateMemberMessage"
      novalidate
      class="edit-message-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editMember.title"
        submit-title="update"
      />
      <div class="form-content content">
        <!--    PAGE TOOLBAR    -->
        <ClPageToolbar
          isAdditionalParam
          @toggleAdditionalParam="handleToggleAdditionalParam"
          @toggleEditorToolbar="handleToggleEditorToolbar"
        />
        <!--   EventRefType   -->
        <ClFormRow
          :label="texts.editMember.eventRefTypeLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.eventRefType"
              :options="eventRefTypeOptions"
              :multiple="false"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="false"
              @tag="addEventRefType"
              required
              :invalidFeedback="texts.invalidFeedback"
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': eventRefTypeValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--   EventRefId   -->
        <ClFormRow
          :label="texts.editMember.eventRefIdLabel"
          :toolTipText="descriptions.subject"
        >
          <template #content>
            <CInput
              :placeholder="texts.editMember.eventRefIdPlaceholder"
              v-model="formData.eventRefId"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>
        <!--   Message Type   -->
        <ClFormRow
          :label="texts.editMember.typeLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.messageType"
              :options="messageTypeOptions"
              :multiple="false"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="messageTypeValidate"
              @tag="addMessageType"
              required
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': messageTypeValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--   Status   -->
        <ClFormRow
          :label="texts.editMember.statusLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.status"
              :options="statusOptions"
              :multiple="false"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              @tag="addStatus"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="statusValidate"
              required
              class="custom-multi-select zq--multiselect"
            />
          </template>
        </ClFormRow>
        <!--   Members   -->
        <ClFormRow
          :label="texts.editMember.membersLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.memberId"
              :options="memberOptions"
              :multiple="true"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              @tag="addMember"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="memberIdValidate"
              required
              label="name"
              track-by="id"
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': memberIdValidate === false}"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
            <img
              src="../../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <ClFormRow
          :label="texts.editMember.expiresLabel"
          xl-content="5"
          :toolTipText="descriptions.till"
        >
          <template #content>
            <DatePicker
              :dateProp="expires"
              @input="setExpiry"
              :placeholder="texts.editMember.expiresPlaceholder"
              :is-valid="expiryValidate"
              required
              :class="{'zq--select--error': expiryValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--  Subject  -->
        <ClFormRow
          :label="texts.editMember.subjectLabel"
          :toolTipText="descriptions.subject"
        >
          <template #content>
            <CInput
              :placeholder="texts.editMember.subjectPlaceholder"
              v-model="formData.subject"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="subjectValidate"
            />
          </template>
        </ClFormRow>
        <!--  BODY-->
        <ClFormRow
          :label="texts.editMember.bodyLabel"
          :toolTipText="descriptions.body"
        >
          <template #content>
            <ClEditor
              :is-toolbar="isToolbar"
              @change="onBodyChange"
              :value-prop="formData.body"
              :placeholder="texts.editMember.bodyPlaceholder"
              :options="bodyOptions"
              :is-valid="bodyValidate"
            />
          </template>
        </ClFormRow>
        <!--    Additional Param     -->
        <div v-show="showParam">
          <!--     Tags     -->
          <ClFormRow :label="texts.editMember.tagsLabel" tool-tip-text="Tags">
            <template #content>
              <multiselect
                v-model="formData.tags"
                :options="tagOptions"
                :multiple="true"
                :preserve-search="true"
                :taggable="true"
                :hide-selected="true"
                :selectLabel="selectLabel"
                :tagPlaceholder="tagPlaceholder"
                @tag="addTags"
                class="custom-multi-select zq--multiselect"
              >
                <template #caret>
                  <div></div>
                </template>
              </multiselect>
              <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon"
              >
            </template>
          </ClFormRow>
          <!--    CUSTOM FIELDS    -->
          <ClFormRow :label="texts.editMember.customFieldsLabel" :toolTipText="descriptions.customFields">
            <template #content>
              <multiselect
                v-model="formData.customFields"
                :options="customFieldsOptions"
                :multiple="true"
                :preserve-search="true"
                :taggable="true"
                :hide-selected="true"
                @tag="addCustomFields"
                :selectLabel="selectLabel"
                :tagPlaceholder="tagPlaceholder"
                label="name"
                track-by="id"
                :placeholder="texts.editMember.customPlaceholder"
                class="custom-multi-select zq--multiselect__search zq--multiselect"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
              </multiselect>
              <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon"
              >
            </template>
          </ClFormRow>
          <!--    METADATA    -->
          <ClFormRow
            :label="texts.editMember.metadataLabel"
            tooltipPosition="label"
            xl-label="12"
            md-content="12"
            xl-content="12"
          >
            <template #content>
              <CMetaInputs
                v-on:change="addMeta"
                :fields="formData.metadata"
              />
            </template>
          </ClFormRow>
        </div>
      </div>
    </CForm>
  </div>
</template>

<script>
import CreateHeader from "@/shared/components/CreateHeader";
import CMetaInputs from '@/components/forms/CMetaInputs';
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClEditor from "@/shared/components/formComponents/ClEditor";
import ClPageToolbar from '@/shared/components/ClPageToolbar';
import DatePicker from '@/shared/components/DatePicker';
import {mapActions, mapGetters} from "vuex";
import {messaging} from "@/config/descriptions/messaging.json";
import {formConfig, pageConfig} from "@/config";
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import {cloneDeep} from "lodash";
import {inboxTexts} from "@/config/pageTexts/inbox.json";

export default {
  name: "EditMemberMessage",
  components: {
    CreateHeader,
    CMetaInputs,
    ClFormRow,
    ClEditor,
    ClPageToolbar,
    DatePicker,
  },
  data() {
    return {
      formData: {
        memberId: [],
        messageType: '',
        subject: '',
        body: '',
        status: '',
        translatableFields: [],
        constraints: '',
        scheduling: '',
        translations: [],
        metadata: [],
        tags: [],
        eventRefType: '',
        eventRefId: '',
        expires: '',
        customFields: [],
      },
      descriptions: {
        ...messaging.create.message
      },
      texts: {
        ...inboxTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      showParam: false,
      isToolbar: false,
      bodyOptions: {
        placeholder: inboxTexts.editMember.bodyPlaceholder,
      },
      tagOptions: ['tag1', 'tag2', 'tag3'],
      eventRefTypeOptions: ['Competition', 'Contest', 'Achievement', 'User', 'Notification'],
      messageTypeOptions: [
        'Achievement',
        'Ticket',
        'Reward',
        'Text',
        'Notification',
        'InboxItem',
        'NotificationInboxItem',
      ],
      customFieldsOptions: [],
      statusOptions: [
        'New',
        'Draft',
      ],
      expires: '',
      eventRefTypeValidate: null,
      messageTypeValidate: null,
      subjectValidate: null,
      bodyValidate: null,
      statusValidate: null,
      expiryValidate: true,
      memberIdValidate: null,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  computed: {
    ...mapGetters('messages', ['loading', 'singleMessage']),
    ...mapGetters('members', ['members']),
    memberOptions() {
      let memberOptions = [];
      if (this.members) {
        this.members.forEach(member => {
          memberOptions.push({
            name: member.name,
            id: member.id
          })
        })
      }
      return memberOptions;
    }
  },
  watch: {
    singleMessage: {
      deep: true,
      handler: function (val) {
        this.formData = cloneDeep(val);
      },
    },
    formData: {
      deep: true,
      handler: function (val) {
        this.validateFields(val);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('messages', ['handleGetMemberMessageById', 'handleUpdateMemberMessage']),
    ...mapActions('members', ['handleGetMembers']),
    ...mapActions('customFields', ['handleGetCustomFields']),
    initialize() {
      this.handleGetMemberMessageById(this.$route.params.id)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].subject
          });
        });
      this.handleGetMembers({
        queryData: {},
        sortBy: this.sortBy,
        skip: 0,
      });
      this.handleGetCustomFields({
        queryData: {},
        sortBy: this.sortBy,
        skip: 0,
        limit: this.itemsPerPage,
      }).then((items) => {
        items.forEach(item => {
          this.customFieldsOptions.push({id: item.id, name: item.name});
        })
      });
    },
    updateMemberMessage() {
      this.eventRefTypeValidate = !!this.formData.eventRefType;
      this.messageTypeValidate = !!this.formData.messageType;
      this.subjectValidate = !!this.formData.subject;
      this.bodyValidate = !!this.formData.body;
      this.statusValidate = !!this.formData.status;
      this.expiryValidate = !!this.formData.expires;
      this.memberIdValidate = !!this.formData.memberId.length;

      let memberIds = [];
      this.formData.memberId.forEach(member => {
        memberIds.push(member.id);
      })

      const body = {
        id: this.formData.id,
        customFields: this.formData.customFields,
        tags: this.formData.tags,
        metadata: this.formData.metadata,
        eventRefType: this.formData.eventRefType,
        eventRefId: this.formData.eventRefId,
        messageType: this.formData.messageType,
        subject: this.formData.subject,
        body: this.formData.body,
        status: this.formData.status,
        expiry: new Date(this.formData.expires),
        memberId: memberIds,
        translations: this.formData.translations,
      };

      if (
        this.eventRefTypeValidate
        && this.messageTypeValidate
        && this.subjectValidate
        && this.bodyValidate
        && this.statusValidate
        && this.expiryValidate
        && this.memberIdValidate
      ) {
        this.handleUpdateMemberMessage(body);
      }
    },
    validateFields(val) {
      if (val.expires) this.expiryValidate = true;
      if (val.body) this.bodyValidate = true;
      if (val.subject) this.subjectValidate = true;
      if (val.eventRefType) this.eventRefTypeValidate = true;
      if (val.messageType) this.messageTypeValidate = true;
      if (val.memberId.length) this.memberIdValidate = true;
    },
    handleToggleAdditionalParam(val) {
      this.showParam = val;
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
    addEventRefType(eventRefType) {
      this.formData.eventRefType = eventRefType;
    },
    addMessageType(messageType) {
      this.formData.messageType = messageType;
    },
    addStatus(status) {
      this.formData.status = status;
    },
    addMember(newMember) {
      this.memberOptions.push(newMember);
    },
    setExpiry(date) {
      const d = Date.parse(date);
      this.formData.expires = date ? new Date(d).toISOString() : "";
      this.expires = date;
      this.validateFields(this.formData);
    },
    onBodyChange(html) {
      this.formData.body = html;
    },
    addCustomFields(val) {
      this.customFieldsOptions.push(val);
      this.formData.customFields = [...this.formData.customFields, val]
    },
    addTags(newTag) {
      this.tagOptions.push(newTag);
      this.formData.tags.push(newTag);
    },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
  },
}
</script>

<style lang="scss">
.edit-message {
  height: 100%;
  &-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
  .multiselect.custom-multi-select.zq--multiselect{
    .multiselect__select {
      &:before {
        display: none;
      }
    }
  }
  .zq--form-row.row .zq--form-row--content {
    max-width: 33%;
  }
  .zq--data-picker.mx-datepicker{
    width: 100%;
    min-width: auto;
  }
}
</style>